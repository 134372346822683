import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewBrandDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const BrandView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const brand_id = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewBrandDetail(brand_id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.payload);
          if (res?.payload?.payload?.brand_logo) {
            setShowImage(
              res?.payload?.payload?.brand_logo
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view brand details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.brand_name && (
                <div className="information_details_cont">
                  <label className="label">Brand Name</label>:
                  <p className="details ">{initialValue?.brand_name}</p>
                </div>
              )}
              
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
              {initialValue?.created_at && (
                <div className="information_details_cont">
                  <label className="label ">Created at</label>:
                  <p className="details "><Moment format="MM/DD/YYYY">{initialValue?.created_at}</Moment></p>
                </div>
              )}

              <div className="information_details_cont ">
                <label className="label">Photo</label>:
                <div className="view_image_container ">
                  <ProfilePhoto src={showimage} />
                </div>
              </div>
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default BrandView;
