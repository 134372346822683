import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewUserDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const UserView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;
  console.log(previousPageCount , "previousPageCount")

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const user_id = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewUserDetail(user_id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.userData);
          if (res?.payload?.userData?.profile_picture) {
            setShowImage(
              res?.payload?.userData?.profile_picture
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view User details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.full_name && (
                <div className="information_details_cont">
                  <label className="label">Name </label>:
                  <p className="details ">{initialValue?.full_name}</p>
                </div>
              )}
              {initialValue?.email && (
                <div className="information_details_cont">
                  <label className="label ">Email</label>:
                  <p className="details ">{initialValue?.email}</p>
                </div>
              )}
              {initialValue?.mobile && (
                <div className="information_details_cont">
                  <label className="label ">Mobile </label>:
                  <p className="details ">
                    {initialValue?.mobile
                      ? `${initialValue?.mobile}`
                      : "NA"}
                  </p>
                </div>
              )}
              {initialValue?.location && (
                <div className="information_details_cont">
                  <label className="label ">Location</label>:
                  <p className="details ">
                    {initialValue?.location ? initialValue?.location : "NA"}
                  </p>
                </div>
              )}
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
              {initialValue?.dob && (
                <div className="information_details_cont">
                  <label className="label ">DOB</label>:
                  <p className="details ">
                  <Moment format="MM/DD/YYYY">{initialValue?.dob}</Moment>
                  </p>
                </div>
              )}
              {initialValue?.gender && (
                <div className="information_details_cont">
                  <label className="label ">Gender</label>:
                  <p className="details ">{initialValue?.gender}</p>
                </div>
              )}
              {initialValue?.current_city && (
                <div className="information_details_cont">
                  <label className="label ">Current City</label>:
                  <p className="details ">{initialValue?.current_city}</p>
                </div>
              )}
              {initialValue?.user_name && (
                <div className="information_details_cont">
                  <label className="label">User Name </label>:
                  <p className="details ">{initialValue?.user_name}</p>
                </div>
              )}
              {initialValue?.bio && (
                <div className="information_details_cont">
                  <label className="label">Bio </label>:
                  <p className="details ">{initialValue?.bio}</p>
                </div>
              )}
              <div className="information_details_cont ">
                <label className="label">Photo</label>:
                <div className="view_image_container ">
                  <ProfilePhoto src={showimage} />
                </div>
              </div>
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { page : 1  } })}>Back</p>
    </section>
  );
};
export default UserView;
