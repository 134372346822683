import React, { useEffect, useState } from "react";
import './products.css';
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegHeart, FaShare } from "react-icons/fa";
import { BsChatText } from "react-icons/bs";
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { viewUserDetail } from "../../../../helpers/Redux/api";

const VendorProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [initialValue, setInitialValue] = useState([]);
    const [loading, setLoading] = useState(true);
    const previousPageCount = location.state?.page;
    const user_id = location.pathname?.split("/")[3];

    const handleGetData = () => {
        setLoading(true);
        try {
            dispatch(viewUserDetail(user_id)).then((res) => {
                if (res?.payload?.status === true) {
                    setLoading(false);
                    setInitialValue(res?.payload?.posts);
                } else {
                    setLoading(false);
                    setInitialValue([]);
                }
            });
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    useEffect(() => {
        handleGetData();
    }, []);

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };


    return (
        <>
            <div className="posts-main">
                <div className="row">
                    {initialValue.map((post, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="post-box">
                                <h6>{post.postCreationTime}</h6>
                                <p>{post.description}</p>
                               
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
        </>
    );
};

export default VendorProducts;
