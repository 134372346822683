import React, { useEffect, useState } from "react";
import './members.css';
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewGroupDetail } from "../../../../helpers/Redux/api";

const GroupMembers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [members , setMembers] = useState([])

    const groupId = location.pathname?.split("/")[3];

    const handlegetdata = () => {
    //   setLoading(true);
      try {
        dispatch(viewGroupDetail(groupId)).then((res) => {
         
          if (res?.payload?.status === true) {
            // setLoading(false);
            setMembers(res?.payload?.payload?.members);
           
          } else {
            // setLoading(false);
            // setInitialValue(initialValue);
          }
        });
      } catch (error) {
        console.log(error, "from view Group details");
      }
    };
    useEffect(() => {
      handlegetdata();
    }, []);
    

    return (
        <>
        <div className="members-main">
            <div className="row">
                {
                    members?.map((res) => (
                        <div className="col-md-4" onClick={()=> navigate(`/user/view/${res.user_id}`)} >
                            <div className="member-box">
                                {/* <img src="https://coding-server-s3bucket.s3.amazonaws.com/bikeRiderpublic/user/1718339340518-image.jpg" alt="profile_image" className="img-fluid" /> */}
                                <img src={res?.profile_picture ? 
                                        `${res?.profile_picture}`  
                                          : noimage} alt="profile_image" className="img-fluid"/>
                                <h4>{res.full_name}</h4>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        

        <p className="btn btn-success mt-4" onClick={() => navigate(-1)}>Back</p>
        </>
    )
}

export default GroupMembers;