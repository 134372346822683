import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
// import './edit.css';
import header_image_thumg from '../../../../src/assets/images/users/noimg.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { brandUpdate, viewBrandDetail } from "../../../helpers/Redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  
import { toast } from "react-toastify";
import Moment from "react-moment"; 

const EditBrand = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [startDate, setStartDate] = useState(new Date());
    const [sendImage, setSendImage] = useState(null);
    const [status, setstatus] = useState(0);

    const [loading, setLoading] = useState(false);

    const brand_id = location.pathname?.split("/")[3];
    console.log(brand_id)

    const handleImageUpload = (e) => {
      let file = e.target.files[0];
      setShowImage(URL.createObjectURL(file));
      setSendImage(file);
    };
    
    const [initialValue, setInitialValue] = useState({
        brandName: '',
    });
  console.log(initialValue)
    
    const handlegetdata = () => {
      setLoading(true);
      try {
        dispatch(viewBrandDetail(brand_id)).then((res) => {
          if (res?.payload?.status === true) {
            setLoading(false);
              setInitialValue({
                  brandName: res?.payload?.payload.brand_name,
            });
            if (res?.payload?.payload?.brand_logo) {
              setShowImage(
                res?.payload?.payload?.brand_logo
              );
              setSendImage(
                res?.payload?.payload?.brand_logo
              );
            }
          } else {
            setLoading(false);
            setInitialValue(initialValue);
          }
        });
      } catch (error) {
        console.log(error, "from view Brand details");
      }
    };
    useEffect(() => {
      handlegetdata();
    }, []);
  
    const handleSubmit = (values, { resetForm }) => {
      console.log(sendImage, 'sendImage')
  
      // Create a new FormData object
      const formData = new FormData();
  
      // Append form values to FormData
      formData.append('brand_name', values.brandName);
      formData.append('brand_logo', sendImage); // Append the image file
  
      // Create request options with the FormData
      const requestOptions = {
        brand_id: brand_id,
        body:{
          method: 'POST',
          body: formData,
          headers: {
          'authorization': `${localStorage.getItem('token')}`  
        }
        }
        
      };
  
      try {
        dispatch(brandUpdate(requestOptions)).then((res) => {
          if (res?.payload?.status === true) {
            toast.success(res?.payload?.message);
            navigate(-1)
            resetForm()
          } else {
            toast.error(res?.payload?.message);
          }
        });
  
      } catch (error) {
        console.log(error, 'error')
      }
    }

    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Edit Brand Details</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
              {!loading &&
                <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                >
                  {(formik)=> (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6 mb-4'>
                        <label htmlFor="brandName">Brand Title</label>
                        <Field className="form-control" placeholder="Brand Name" name="brandName" required="" />
                      </div>
                            
                      <div className='col-md-12'>
                        <div className="upload_image_container">
                          <img src={ showimage} alt="logo" className="img-fluid uploaded_image " />
                          <label htmlFor="upload-photo1">Upload Photo</label>
                            <input type="file" name="BrandLogo" id="upload-photo1" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                      </div>
                      <div className="col-md-3">
                        <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</button>
                      </div>
                    </div>
                  </Form>
                  )}
                </Formik>
              }
                </div>
            </div>    
        </div>
      </div>
    );
  }
  
  export default EditBrand;