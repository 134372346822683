import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewReportsDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const ReportView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const id = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewReportsDetail(id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.data);
          console.log(res?.payload?.data);
          if (res?.payload?.data?.user?.profile_picture) {
            setShowImage(
              res?.payload?.data?.user?.profile_picture
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Vendor details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.report?.report_text && (
                <div className="information_details_cont">
                  <label className="label">Report  Title</label>:
                  <p className="details ">{initialValue?.report?.report_text }</p>
                </div>
              )}
              {initialValue?.report?.created_at && (
                <div className="information_details_cont">
                  <label className="label">Report  Date</label>:
                  <p className="details "><Moment format="MM/DD/YYYY">{initialValue?.report?.created_at}</Moment></p>
                </div>
              )}
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.report?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
              
              <h3 className="mt-5 ms-2" >User</h3>
                  
              <div className="mb-5">
                <div className="information_details_cont ">
                  {/* <label className="label">Photo</label>: */}
                  <div className="view_image_container ">
                    <ProfilePhoto src={showimage} />
                  </div>
                </div>
                {initialValue?.user?.full_name && (
                  <div className="information_details_cont">
                    <label className="label">Full Name</label>:
                    <p className="details ">{initialValue?.user?.full_name}</p>
                  </div>
                )}
                {initialValue?.user?.email && (
                  <div className="information_details_cont">
                    <label className="label">Email</label>:
                    <p className="details ">{initialValue?.user?.email}</p>
                  </div>
                )}
                
              </div>
              
              <h3 className="mt-5 ms-2" >Posts</h3>
                  
              <div className="mb-5">
                {initialValue?.post?.user?.profile_picture && (
                  <div className="information_details_cont">
                    {/* <label className="label">Photo:</label> */}
                    <div className="view_image_container">
                      <ProfilePhoto src={initialValue?.post?.user?.profile_picture} />
                    </div>
                  </div>
                )}
                {initialValue?.post?.user?.full_name && (
                  <div className="information_details_cont">
                    <label className="label">Author</label>:
                    <p className="details ">{initialValue?.post?.user?.full_name}</p>
                  </div>
                )}
                {initialValue?.post?.createdAt && (
                  <div className="information_details_cont">
                    <label className="label">Created at</label>:
                    {/* <p className="details ">{initialValue?.post?.createdAt}</p> */}
                    <p className="details "><Moment format="MM/DD/YYYY">{initialValue?.post?.createdAt}</Moment></p>
                  </div>
                )}
                {initialValue?.post?.email && (
                  <div className="information_details_cont">
                    <label className="label">Email</label>:
                    <p className="details ">{initialValue?.post?.email}</p>
                  </div>
                )}
                {initialValue?.post?.description && (
                  <div className="information_details_cont">
                    <label className="label">Description</label>:
                    <p className="details ">{initialValue?.post?.description}</p>
                  </div>
                )}
                {initialValue?.post?.location && (
                  <div className="information_details_cont">
                    <label className="label">Location</label>:
                    <p className="details ">{initialValue?.post?.location}</p>
                  </div>
                )}
                
                {initialValue?.post?.files?.[0]?.url && (
                  <div className="information_details_cont">
                    <label className="label">Post Photo:</label>
                    <div className="view_image_container">
                      <ProfilePhoto src={initialValue?.post?.files?.[0]?.url} />
                    </div>
                  </div>
                )}
                
                {/* <div className="information_details_cont ">
                  <label className="label">Photo</label>:
                  <div className="view_image_container ">
                    <ProfilePhoto src={showimage} />
                  </div>
                </div> */}
              </div>

        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default ReportView;
