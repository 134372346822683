import React, { useEffect, useState } from "react";
import './edit.css';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import header_image_thumg from '../../../../src/assets/images/users/noimg.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { eventUpdate, viewEventDetail } from "../../../helpers/Redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";   
import { toast } from "react-toastify";
import * as moment from 'moment'

const EditEvent = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);

    const [status, setstatus] = useState(0);

    const [loading, setLoading] = useState(false);

    const event_Id = location.pathname?.split("/")[3];

    const [sendImage, setSendImage] = useState(null);
    const handleImageUpload = (e) => {
        let file = e.target.files[0];
        setShowImage(URL.createObjectURL(file));
        setSendImage(file);
        };

    const [startDate, setStartDate] = useState(new Date());
    const [lastDate, setLasttDate] = useState(new Date());

    const [initialValue, setInitialValue] = useState({
        eventTitle: '',
        ownerName: '',
        startDate: '',
        lastDate: '',
        reportingTime: '',
        startingPoint: '',
        destination: '',
        maxRiders: '',
        description: '',
        eventImage: '',
    });

    const handlegetdata = () => {
        setLoading(true);
        try {
          dispatch(viewEventDetail(event_Id)).then((res) => {
            if (res?.payload?.status === true) {
              setLoading(false);
                setInitialValue({
                    eventTitle: res?.payload?.payload[0].title,
                    ownerName: res?.payload?.payload[0].owner_name,
                    startDate: res?.payload?.payload[0].startTime,
                    lastDate: res?.payload?.payload[0].endtTime,
                    reportingTime: res?.payload?.payload[0].reportingTime,
                    startingPoint: res?.payload?.payload[0].startingPoint,
                    destination: res?.payload?.payload[0].destination,
                    maxRiders: res?.payload?.payload[0].maxRiders,
                    description: res?.payload?.payload[0].description,
              });
              if (res?.payload?.payload[0]?.files[0]?.url) {
                setShowImage(
                  res?.payload?.payload[0]?.files[0]?.url);
              }
            } else {
              setLoading(false);
              setInitialValue(initialValue);
            }
          });
        } catch (error) {
          console.log(error, "from view Event details");
        }
      };
      useEffect(() => {
        handlegetdata();
      }, []);

    
      const validationscheme = Yup.object({
        eventTitle: Yup.string().required(" Title is required"),
        ownerName: Yup.string().required(" Owner name is required"),
        startingPoint: Yup.string().required(" Statting point is required"),
        destination: Yup.string().required("Destination is required"),
        reportingTime: Yup.string().required("Reporting time is required"),
        maxRiders: Yup.string().required("Max riders is required"),
        description: Yup.string().required("Description is required"),
      });
    
    
    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Edit Event Details</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                {!loading &&
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationscheme}
                    onSubmit={(values, {resetForm}) => { 
                        console.log(values)
                        const requestOption = {
                            event_Id: event_Id,
                            data: {
                                title: values.eventTitle,
                                ownerName: values.ownerName,
                                startTime: values.startDate,
                                endTime: values.lastDate,
                                reportingTime: values.reportingTime,
                                startingPoint: values.startingPoint,
                                destination: values.destination,
                                maxRiders:  values.maxRiders,
                                description: values.description,
                            }
                        }
                        try {
                            dispatch(eventUpdate(requestOption)).then((res) => {
                                if (res?.payload?.status === true) {
                                    toast.success(res?.payload?.message);
                                    navigate(-1)
                                    resetForm()
                                } else {
                                    toast.error(res?.payload?.message);
                                }
                            });
                
                        } catch (error) {
                            console.log(error , 'error')
                        }
                    }}
                    
                >
                    {(formik)=> (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="eventTitle">Event Title</label>
                                <Field className="form-control" placeholder="Event Title" name="eventTitle"  />
                                <ErrorMessage name='eventTitle' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="ownerName">Owner Name</label>
                                <Field className="form-control" name="ownerName" placeholder="Owner Name" />
                                <ErrorMessage name='ownerName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="startingPoint">Starting Point</label>
                                <Field className="form-control" name="startingPoint" placeholder="Starting Point" />
                                <ErrorMessage name='startingPoint' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="destination">Destination</label>
                                <Field className="form-control" name="destination" placeholder="Destination" />
                                <ErrorMessage name='destination' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="reportingTime">Reporting Time</label>
                                <Field className="form-control" name="reportingTime" placeholder="Reporting Time" />
                                <ErrorMessage name='reportingTime' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="startDate">Start Date</label>
                                <DatePicker className="form-control w-100" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yyyy-MM-dd" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="lastDate">Last Date</label>
                                <DatePicker className="form-control w-100" selected={lastDate} onChange={(date) => setLasttDate(date)} dateFormat="yyyy-MM-dd" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="maxRiders">Max Riders</label>
                                <Field className="form-control" name="maxRiders" placeholder="Max Riders" />
                                <ErrorMessage name='maxRiders' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-12 mb-4'>
                                <label htmlFor="description">Description</label>
                                <Field className="form-control" name="description" component="textarea" rows="4" placeholder="Description" />
                                <ErrorMessage name='description' component="div" className="text-danger" />
                            </div>
                            
                            {/* <div className='col-md-6'>
                                <div className="upload_image_container">
                                    <img src={showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label htmlFor="upload-photo1">Upload Photo</label>
                                    <Field type="file" name="eventImage" id="upload-photo1" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                                </div>
                            </div> */}
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                }
                </div>
            </div>    
        </div>
      </div>
    );
  }
  
  export default EditEvent;