import React, { useEffect, useState } from "react";
import './posts.css';
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegHeart, FaShare } from "react-icons/fa";
import { BsChatText } from "react-icons/bs";
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { viewUserDetail } from "../../../../helpers/Redux/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player'
const UserPosts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [initialValue, setInitialValue] = useState([]);
    const [loading, setLoading] = useState(true);
    const previousPageCount = location.state?.page;
    const user_id = location.pathname?.split("/")[3];

    const handleGetData = () => {
        setLoading(true);
        try {
            dispatch(viewUserDetail(user_id)).then((res) => {
                if (res?.payload?.status === true) {
                    setLoading(false);
                    setInitialValue(res?.payload?.posts);
                } else {
                    setLoading(false);
                    setInitialValue([]);
                }
            });
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    useEffect(() => {
        handleGetData();
    }, []);

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };


    return (
        <>
            <div className="posts-main">
                <div className="row">
                    {initialValue.map((post, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="post-box">
                                <h6>{post.postCreationTime}</h6>
                                <p>{post.description}</p>
                                {post.files.length < 1 ? (
                                    <div className="">
                                        {/* <a href="#"><img src={noimage} alt="profile_image" className="view_image_container" /></a> */}
                                        <a href="#">
                                            <div className="post-box-media">
                                                <img src={noimage} alt="profile_image" className="view_image_container" />
                                            </div>
                                        </a>
                                    </div>
                                ) : (
                                    <div className="">
                                        {post.files[0].type === "photo" ? (
                                            <a href="#">
                                                <div className="post-box-media">
                                                    <img src={post.files[0].url} alt="profile_image" className="img-fluid view_image_container" />
                                                </div>
                                            </a>
                                        ) : post.files[0].type === "video" ? (
                                            <div className="view_video_container">
                                                <ReactPlayer url={post.files[0].url} className="post-box-media" controls />
                                            </div>
                                        ) : (
                                            <img src={noimage} alt="profile_image" className="view_image_container" />
                                        )}
                                    </div>
                                )}

                                {/* <Slider {...settings}>
                                    {post.files.length < 1 ? (
                                        <div key={1} className="d-flex justify-content-center align-items-center">
                                            <a href="#"><img src={noimage} alt="profile_image" className="view_image_container" /></a>
                                        </div>
                                    ) : (
                                        post.files.map((file, idx) => (
                                            <div key={idx} className="d-flex justify-content-center align-items-center">
                                                {file.type === "photo" ? (
                                                    <a href="#">
                                                        <img src={file.url} alt="profile_image" className="img-fluid view_image_container" />
                                                    </a>
                                                ) : file.type === "video" ? (
                                                    <div className="view_video_container">
                                                        <ReactPlayer url={file.url} className="" controls />
                                                    </div>
                                                ) : (
                                                    <img src={noimage} alt="profile_image" className="view_image_container" />
                                                )}
                                            </div>
                                        ))
                                    )}
                                </Slider> */}



                                <div className="post-foot-icons mt-3">
                                    <div className="position-relative">
                                        <a href="#"><FaRegHeart /></a>
                                        <small className="like-count">{post.likeCount}</small>
                                    </div>
                                    <div className="position-relative">
                                        <a href="#"><BsChatText /></a>
                                        <small className="comment-count">{post.commentCount}</small>
                                    </div>
                                    <div className="">
                                        <a href="#"><FaShare /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
        </>
    );
};

export default UserPosts;
