import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { productView, viewBrandDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const ProductView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const productId = location.pathname?.split("/")[3];

  const getProductDetail = async () => {
    setLoading(true)
    try {
      const response = await dispatch(productView(productId));
  
      if (response?.payload?.status === true) {
        setInitialValue(response.payload.payload[0])
    setLoading(false)

      } else {
    setLoading(false)

      }
      
    } catch (error) {
      console.log(error);
    }
  };
   
  useEffect(() => {
    getProductDetail();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.name && (
                <div className="information_details_cont">
                  <label className="label">Product Name</label>:
                  <p className="details ">{initialValue?.name}</p>
                </div>
              )}
                  {initialValue?.vendor_name && (
                <div className="information_details_cont">
                  <label className="label">Vendor Name</label>:
                  <p className="details ">{initialValue?.vendor_name}</p>
                </div>
              )}
                  {initialValue?.category_name && (
                <div className="information_details_cont">
                  <label className="label">Category Name</label>:
                  <p className="details ">{initialValue?.category_name}</p>
                </div>
              )}

                  {initialValue?.description && (
                <div className="information_details_cont">
                  <label className="label">Description</label>:
                  <p className="details ">{initialValue?.description}</p>
                </div>
              )}
             <h3 className="mt-5 ms-2" >  Variants </h3>

             {initialValue?.variants?.map((res , i)=>(
              <>
              <div className="mb-5">

               {res?.color_name && (
                <div className="information_details_cont">
                  <label className="label">Color Name</label>:
                  <p className="details ">{res?.color_name}</p>
                </div>
              )}
               {res?.sizes && (
                <div className="information_details_cont">
                  <label className="label">Sizes</label>:
                  {
                    res?.sizes?.map((size)=>(
                  <p className="details ">{size.size_name}</p>

                    ))
                  }
                </div>
              )}
               {res?.upc && (
                <div className="information_details_cont">
                  <label className="label">UPC</label>:
                  <p className="details ">{res?.upc}</p>
                </div>
              )}
               {res?.sku && (
                <div className="information_details_cont">
                  <label className="label">SKU</label>:
                  <p className="details ">{res?.sku}</p>
                </div>
              )}
               {res?.price && (
                <div className="information_details_cont">
                  <label className="label">Price</label>:
                  <p className="details ">{res?.price}</p>
                </div>
              )}
               {res?.quantity && (
                <div className="information_details_cont">
                  <label className="label">Quantity</label>:
                  <p className="details ">{res?.quantity}</p>
                </div>
              )}
               {res?.discount && (
                <div className="information_details_cont">
                  <label className="label">Discount</label>:
                  <p className="details ">{res?.discount}</p>
                </div>
              )}
              {
                res?.images.length  > 1 && (

              <div className="information_details_cont ">
                <label className="label">Photo</label>:
                {
                  res?.images?.map((image ,i)=>(
                <div className="view_image_container " key={i}>
                  <ProfilePhoto src={image} />
                </div>
                  ))
                }
                
              </div>
                )
              }
              </div>
             
              </>
             ))

             }
              
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
             

            
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default ProductView;
