import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import AppContent from "./appcontent/AppContent";

const FullLayout = () => {
  const location = useLocation();
  const isNotFound = location.pathname === "/404"; // Adjust this path if needed

  return (
    <main>
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        {!isNotFound && (
          <aside className="sidebarArea shadow" id="sidebarArea">
            <Sidebar />
          </aside>
        )}
        {/********Content Area**********/}

        <div className="contentArea">
          {/********header**********/}
          {!isNotFound && <Header />}
          {/********Middle Content**********/}
          <div className="wrapper">
            <AppContent />
          </div>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
