import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewGroupDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const GroupView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const groupId = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewGroupDetail(groupId)).then((res) => {
       
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.payload);
          if (res?.payload?.payload?.image) {
            setShowImage(
              res?.payload?.payload?.image
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Group details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.name && (
                <div className="information_details_cont">
                  <label className="label">Group Name </label>:
                  <p className="details ">{initialValue?.name}</p>
                </div>
              )}
              {initialValue?.owner_name && (
                <div className="information_details_cont">
                  <label className="label">Owner Name </label>:
                  <p className="details ">{initialValue?.owner_name}</p>
                </div>
              )}
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
              
              {initialValue?.memberCount !== null && initialValue?.memberCount !== undefined && (
                <div className="information_details_cont">
                  <label className="label">Members</label>:
                  <p className="details">
                    {initialValue.memberCount}
                  </p>
                </div>
              )}
              {initialValue?.created_at && (
                <div className="information_details_cont">
                  <label className="label ">Creates at</label>:
                  <p className="details ">
                  <Moment format="MM/DD/YYYY">{initialValue?.created_at}</Moment>
                  </p>
                  
                </div>
              )}

              {initialValue?.description && (
                <div className="information_details_cont">
                  <label className="label ">Description</label>:
                  <p className="details ">{initialValue?.description}</p>
                </div>
              )}
              <div className="information_details_cont ">
                <label className="label">Photo</label>:
                <div className="view_image_container ">
                  <ProfilePhoto src={showimage} />
                </div>
              </div>
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default GroupView;
