import React, { useState, useEffect } from "react";
import noimage from "../../../assets/images/users/noimg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clubStatusUpdate, ClubDelete, ClubRequestLists, AcceptRejectStatus } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ClubRequestList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [recordperpage, setRecordperpage] = useState(10);
    const [search, setSearch] = useState("");
    const [loadingRequests, setLoadingRequests] = useState({}); // Track ongoing requests

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleRequestStatus = async (status, user_id, id) => {
        const requestKey = `${user_id}-${id}`; // Unique key for the request

        // Prevent multiple clicks by checking if the request is already in progress
        if (loadingRequests[requestKey]) return;

        setLoadingRequests((prev) => ({ ...prev, [requestKey]: true }));

        const requestOptions = {
            clubId: id,
            userId: user_id,
            isAccepted: status
        };

        try {
            const res = await dispatch(AcceptRejectStatus(requestOptions));
            if (res?.payload?.status === true) {
                getUsersData();
                toast.success(res?.payload?.message);
            } else {
                toast.error(res?.payload?.message);
            }
        } catch (error) {
            console.log(error, 'error');
        } finally {
            setLoadingRequests((prev) => ({ ...prev, [requestKey]: false }));
        }
    };

    const getUsersData = async () => {
        const requestOption = {
            page: page,
            limit: recordperpage,
            search: search
        };

        try {
            const res = await dispatch(ClubRequestLists(requestOption));
            if (res?.payload?.status === true) {
                setList(res?.payload?.data);
                setTotalCount(res?.payload?.totalCount);
            } else {
                setList([]);
            }
        } catch (error) {
            console.log('user list Error :- ', error);
        }
    };

    useEffect(() => {
        getUsersData();
    }, [page, search]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure you want to delete the club?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0373FF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    dispatch(ClubDelete(id)).then((res) => {
                        if (res?.payload?.success === true) {
                            toast.success(res?.payload?.message);
                            setList(list.filter((elm) => elm?.id !== res?.payload?.deleted_id));
                        } else {
                            toast.error(res?.payload?.message);
                        }
                    });
                } catch (error) {
                    console.log(error, 'error');
                }
            }
        });
    };

    return (
        <>
            <div className="list_page_container">
                <div className="page_name_heading_cont">
                    <div className='container'>
                        <h5>Club Requests</h5>
                    </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <div className="shadow-box">
                            <div className="top_search d-flex justify-content-end text-end mb-4">
                                <input
                                    type="text"
                                    placeholder="Type here..."
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                            <div className="table_container table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Club Name</th>
                                            <th>Owner Name</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((res, i) => (
                                            <tr key={i}>
                                                <td className="common_class_td_width">{res?.name}</td>
                                                <td className="common_class_td_width">{res?.user_name}</td>
                                                <td className="common_class_td_width">{res?.description}</td>
                                                <td>
                                                    <Tooltip title="Click to accept request">
                                                        <button
                                                            className="active"
                                                            onClick={() => handleRequestStatus(true, res.user_id, res?.id)}
                                                            disabled={loadingRequests[`${res.user_id}-${res.id}`]}
                                                        >
                                                            {loadingRequests[`${res.user_id}-${res.id}`] ? "Processing..." : "Accept"}
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <Tooltip title="Click to reject request">
                                                        <button
                                                            className="inactive"
                                                            onClick={() => handleRequestStatus(false, res.user_id, res?.id)}
                                                            disabled={loadingRequests[`${res.user_id}-${res.id}`]}
                                                        >
                                                            {loadingRequests[`${res.user_id}-${res.id}`] ? "Processing..." : "Reject"}
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {total_count >= 10 &&
                                <div className="d-flex justify-content-end align-items-center my-3">
                                    <Pagination
                                        page={page}
                                        setPage={handlePageChange}
                                        total={total_count}
                                        perPage={recordperpage}
                                        className='pagination'
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClubRequestList;
