import React, { useState, useEffect } from "react";
import "./add.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from "../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { categoryList, sizeAdd } from "../../../helpers/Redux/api";

const AddSize = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);


//     const getColorname = (val) => {
//         console.log(val ,"val")
//         let someColor = namedColors.find(color => color.hex === val);
//         if (someColor.name) {
//             setColorname(someColor.name)
//         }
      
//   }


    const [categorylist, setCategoryList] = useState([])

    const [sendImage, setSendImage] = useState(null);
    console.log(sendImage, 'sendImage')
    

    const handleSubmit = (values, { resetForm }) => {
        console.log(sendImage, 'sendImage')
      
        // Create request options with the FormData
        const requestOptions = {
            category_id: values.category,
            size_name: values.size
        };

        try {
            dispatch(sizeAdd(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    navigate(-1)
                    resetForm()
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }
    
    const getUsersData = () =>{
        try {
            dispatch(categoryList()).then((res)=>{
                if(res?.payload?.status === true) {
                    setCategoryList(res?.payload?.data)
                }
                else {
                    setCategoryList([])
                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [])

    const handleInput = (event) => {
        const value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        event.target.value = value;
    };

    const validationscheme = Yup.object({
        category: Yup.string().required(" Category is required"),
        size: Yup.string().required(" Size is required"),
    });

    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Add Size</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                <Formik
                    initialValues={{
                        category: '',
                        size: '',
                    }}
                    onSubmit={ handleSubmit}
                    validationSchema={validationscheme}
                >
                    {(formik)=> (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="category">Category</label>
                                <Field className="form-control" as="select" name="category">
                                    <option value="" label="Select Category" />
                                    {
                                        categorylist.map((res, i)=>(
                                            <option value={res.id} label={res.category_name} key={i} />
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name='category' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="size">Size</label>
                                <Field className="form-control" placeholder="Enter Size" name="size" onInput={handleInput} />
                                <ErrorMessage name='size' component="div" className="text-danger" />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4 g-3">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>    
        </div>
      </div>
    );
};
export default AddSize;
