import React, { useEffect, useState } from "react";
import './edit.css';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import header_image_thumg from '../../../../src/assets/images/users/noimg.png';
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";   
import { useDispatch } from "react-redux";
import { userUpdate, viewUserDetail } from "../../../helpers/Redux/api";
import { toast } from "react-toastify";
import * as moment from 'moment'


const EditUser = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);

    const [status, setstatus] = useState(0);

    const [loading, setLoading] = useState(false);
    
    const user_Id = location.pathname?.split("/")[3];

    const [dobDate, setDobDate] = useState(new Date());

    const [sendImage, setSendImage] = useState(null);
    
    const handleImageUpload = (e) => {
    let file = e.target.files[0];
    setShowImage(URL.createObjectURL(file));
    setSendImage(file);
    };

    const [initialValue, setInitialValue] = useState({
        firstName: '',
        email: '',
        phone: '',
        gender: '',
        dob: '',
        countryCode: '',
        currentCity: '',
        bio: '',
    });
    console.log(initialValue)

    const handlegetdata = () => {
        setLoading(true);
        try {
          dispatch(viewUserDetail(user_Id)).then((res) => {
            if (res?.payload?.status === true) {
              setLoading(false);
                setInitialValue({
                    firstName: res?.payload?.userData.full_name,
                    email: res?.payload?.userData.email,
                    phone: res?.payload?.userData.mobile,
                    gender: res?.payload?.userData.gender,
                    dob: moment(res?.payload?.userData.dob).format('yyyy/MM/DD'),
                    countryCode: res?.payload?.userData.country_code,
                    currentCity: res?.payload?.userData.current_city,
                    bio: res?.payload?.userData.bio,
              });
              if (res?.payload?.userData?.profile_picture) {
                setShowImage(
                  res?.payload?.userData?.profile_picture
                );
              }
            } else {
              setLoading(false);
              setInitialValue(initialValue);
            }
          });
        } catch (error) {
          console.log(error, "from view User details");
        }
      };
      useEffect(() => {
        handlegetdata();
      }, []);
    
      const handleSubmit = (values, { resetForm }) => {
        console.log(sendImage, 'sendImage')
    
        // Create a new FormData object
        const formData = new FormData();
    
        // Append form values to FormData
        formData.append('full_name', values.firstName);
        formData.append('email', values.email);
        formData.append('mobile', values.phone);
        formData.append('gender', values.gender);
        formData.append('dob', values.dob);
        formData.append('country_code', values.countryCode);
        formData.append('current_city', values.currentCity);
        formData.append('bio', values.bio);
        formData.append('profile_picture', sendImage); // Append the image file
        formData.append('user_id', user_Id);
        formData.append('is_active', true);
        
    
        // Create request options with the FormData
        const requestOptions = {
          method: 'POST',
            body: formData,
            headers: {
            'authorization': `${localStorage.getItem('token')}` 
          }
        };
    
        try {
          dispatch(userUpdate(requestOptions)).then((res) => {
            if (res?.payload?.success === true) {
              toast.success(res?.payload?.message);
              navigate(-1)
              resetForm()
            } else {
              toast.error(res?.payload?.message);
            }
          });
    
        } catch (error) {
          console.log(error, 'error')
        }
      }
  
      const validationscheme = Yup.object({
        firstName: Yup.string().required(" Name is required"),
        email: Yup.string().required(" Email is required").email(" Invalid email address"),
        phone: Yup.string().required(" Phone  is required"),
        gender: Yup.string().required("Gender is required"),
        dob: Yup.string().required("DOB is required"),
        countryCode: Yup.string().required("Country code is required"),
        currentCity: Yup.string().required("Current city is required"),
        bio: Yup.string().required("Bio is required"),
      });
    
    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Edit User Details</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                {!loading &&
                <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationscheme}
                >
                            
                    {(formik) => (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="firstName">Name</label>
                                <Field className="form-control" placeholder="Your full name" name="firstName" />
                                <ErrorMessage name='firstName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="email">Email</label>
                                <Field className="form-control" id="email" name="email" placeholder="Your Email" type="email" />
                                <ErrorMessage name='email' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="phone">Mobile</label>
                                <Field className="form-control" placeholder="Your Phone" name="phone" />
                                <ErrorMessage name='phone' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="gender">Gender</label>
                                <Field className="form-control" as="select" name="gender">
                                    <option value="" label="Select Gender" />
                                    <option value="Male" label="Male" />
                                    <option value="Female" label="Female" />
                                    <option value="other" label="Other" />
                                </Field>
                                <ErrorMessage name='gender' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="dob">Date of Birth</label>
                                <DatePicker className="form-control w-100" name="dob" selected={dobDate} onChange={(date) => setDobDate(date)} dateFormat="yyyy-MM-dd" />
                                {/* <Field className="form-control" type="date" placeholder="Your DOB" name="dob" required="" /> */}
                                <ErrorMessage name='dob' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="countryCode">Country Code</label>
                                <Field className="form-control" placeholder="Your Country Code" name="countryCode" />
                                <ErrorMessage name='countryCode' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="currentCity">Current City</label>
                                <Field className="form-control" placeholder="Your Current City" name="currentCity"/>
                                <ErrorMessage name='currentCity' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="bio">Bio</label>
                                <Field className="form-control" placeholder="Your Bio" name="bio" />
                                <ErrorMessage name='bio' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6'>
                                <div className="upload_image_container">
                                    <img src={showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label for="upload-photo1">Upload Photo</label>
                                    <input type="file" name="" id="upload-photo1" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                }
                </div>
            </div>    
        </div>
      </div>
    );
  }
  
  export default EditUser;