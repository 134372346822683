import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Provider } from 'react-redux';
import store from './helpers/Redux/store.js';
import FullLayout from "./layouts/FullLayout";
import Loader from "./layouts/loader/Loader";
//react toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VendorLogin from "./views/vendorLogin/login.jsx";
import ClubLogin from "./views/clubLogin/login.jsx";
import HomePage from "./views/Homepage/homepage.jsx";
import TermsAndConditions from "./views/Homepage/Terms&conditions/terms.jsx";
import ReturnPolicy from "./views/Homepage/ReturnPolicy/return.jsx";
import Policy from "./views/Homepage/policy/Policy.jsx";
import NotFound from "./views/404page/notfound.jsx";
// import UserList from "./views/Users/list.jsx";
// Pages
const Login = React.lazy(() => import("./views/login/Login.jsx"));
const SendLinktoEmail = React.lazy(() => import("./views/ForgotPassword/sendLink/sendlink.jsx"));
const CreatePassword = React.lazy(() => import("./views/ForgotPassword/createPassword/createpassword.jsx"));





const App = () => {


  return (
    <Provider store={store} >
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
    
          <Route path="/" name="Login Page" element={<Login />} />



            <Route path="/admin/login" name="Login Page" element={<Login />} />
            <Route path="/vendor/login" name="Login Page" element={<VendorLogin />} />
            <Route path="/club/login" name="Login Page" element={<ClubLogin />} />


            <Route
              exact
              path="/forgotpassword"
              element={<SendLinktoEmail />}
            />
            <Route
              exact
              path="/create/Password"
              name="Register Page"
              element={<CreatePassword />}
            />
        

            {/* <Route path="/*" name="Home" element={<FullLayout />} /> */}
            <Route path="*" name="notfound" element={<FullLayout />} />



          </Routes>
          <ToastContainer />

        </Suspense>
      </BrowserRouter>
    </Provider>

  );
};

export default App;
