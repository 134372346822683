import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewColorDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";

const ColorView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const color_id = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewColorDetail(color_id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.data[0]);
          if (res?.payload?.data[0]?.vendor_logo) {
            setShowImage(
              res?.payload?.data[0]?.vendor_logo
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Color details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.color_name && (
                <div className="information_details_cont">
                  <label className="label">Color Name</label>:
                  <p className="details ">{initialValue?.color_name}</p>
                </div>
              )}
              {initialValue?.color_code && (
                <div className="information_details_cont">
                  <label className="label ">Color Code</label>:
                  <p className="details ">{initialValue?.color_code}</p>
                </div>
              )}
              {initialValue?.category_name && (
                <div className="information_details_cont">
                  <label className="label ">Category</label>:
                  <p className="details ">{initialValue?.category_name}</p>
                </div>
              )}
              
              <div className="information_details_cont ">
                <label className="label">Color</label>:
                <div className="view_image_container ">
                <div style={{ borderRadius: '50px', width: '60px', height: '60px', backgroundColor: initialValue?.color_code || '#FFFFFF', border: '1px solid #ccc',}}></div>
                </div>
              </div>
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default ColorView;
