import React, { useEffect, useState } from "react";
import './view.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewEventDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";
import Moment from "react-moment";

const EventView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);

  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(true);

  const eventId = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewEventDetail(eventId)).then((res) => {
       
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.payload[0]);
          if (res?.payload?.payload[0]?.files[0]?.url) {
            setShowImage(
              res?.payload?.payload[0]?.files[0]?.url);
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Event details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
      <section className="add_section">
        <div className="view_information_cont_inner">
              {initialValue?.title && (
                <div className="information_details_cont">
                  <label className="label">Event Name </label>:
                  <p className="details ">{initialValue?.title}</p>
                </div>
              )}
              <div className="information_details_cont">
                <label className="label ">Status </label>:{" "}
                <p className="details ">
                  {initialValue?.is_active == "1" ? "Active" : "Inactive"}
                </p>
              </div>
              {initialValue?.startingPoint && (
                <div className="information_details_cont">
                  <label className="label">Starting Point </label>:
                  <p className="details ">{initialValue?.startingPoint}</p>
                </div>
              )}
              {initialValue?.destination && (
                <div className="information_details_cont">
                  <label className="label">Destination </label>:
                  <p className="details ">{initialValue?.destination}</p>
                </div>
              )}
              {initialValue?.totalKms && (
                <div className="information_details_cont">
                  <label className="label">Total Kms </label>:
                  <p className="details ">{initialValue?.totalKms}</p>
                </div>
              )}
              {initialValue?.rideDuration && (
                <div className="information_details_cont">
                  <label className="label">Ride Duration </label>:
                  <p className="details ">{initialValue?.rideDuration}</p>
                </div>
              )}
              

              {initialValue?.startTime && (
                <div className="information_details_cont">
                  <label className="label ">Start time</label>:
                  <p className="details ">
                    {/* <Moment format="MM/DD/YYYY">{initialValue?.startTime}</Moment> */}
                    {initialValue?.startTime}
                  </p>
                </div>
              )}
              {initialValue?.endTime && (
                <div className="information_details_cont">
                  <label className="label ">End time</label>:
                  <p className="details ">
                    {/* <Moment format="MM/DD/YYYY">{initialValue?.startTime}</Moment> */}
                    {initialValue?.endTime}
                  </p>
                </div>
              )}
              {initialValue?.reportingTime && (
                <div className="information_details_cont">
                  <label className="label ">Reporting time</label>:
                  <p className="details ">
                    {/* <Moment format="MM/DD/YYYY">{initialValue?.reportingTime}</Moment> */}
                    {initialValue?.reportingTime}
                  </p>
                </div>
              )}

              {initialValue?.description && (
                <div className="information_details_cont">
                  <label className="label ">Description</label>:
                  <p className="details ">{initialValue?.description}</p>
                </div>
              )}
              <div className="information_details_cont ">
                <label className="label">Photo</label>:
                <div className="view_image_container ">
                  <ProfilePhoto src={showimage} />
                </div>
              </div>
        </div>
        <p className="btn btn-success mt-4" onClick={() => navigate(-1, { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default EventView;
