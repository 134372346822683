import React from 'react';
import './NotFound.css';

const NotFound = () => {

    return (
        <div className="not-found">
            <h1 className="not-found-title">404</h1>
            <p className="not-found-message">Oops! Page not found.</p>
            {/* <a href={localStorage.getItem('userType') == "clubs" ? "/club/login" : localStorage.getItem('userType') == "vendor"  ? "/vendor/login" : "/"} className="not-found-link">Go Back Login</a> */}
            <a href={'/dashboard'} className="not-found-link">Go Back Dashboard</a>
        </div>
    );
};

export default NotFound;